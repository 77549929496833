<template lang="pug">
  v-dialog(
    v-model="openDialog"
    eager
    width="500"
  )
    v-card
      v-toolbar(
        color="green"
        dark
        dense
        flat
      )
        span Create Detail
      v-container
        v-row
          v-col(cols="6")
            v-text-field(
              label="Balance"
              readonly
              :value="balance.phpFormat()"
            )
          v-col(cols="6")
            v-autocomplete(
              label="Account Code"
              :loading="!accountCodes.length"
              v-model="accountCodeId"
              :items="accountCodes"
              item-value="account_code.id"
              item-text="account_code.account_code"
            )
              template(v-slot:selection="{ item }")
                span.primary--text.mr-2 {{ item.account_code.account_code }}
                small.blue-grey--text {{ item.account_code.description }}
              template(v-slot:item="{ item }")
                span.primary--text.mr-2 {{ item.account_code.account_code }}
                small.blue-grey--text {{ item.account_code.description }}
          v-col(cols="6")
            v-text-field(
              label="Amount"
              v-model="amount"
            )
          v-col(cols="6")
            v-text-field(
              label="Description"
              v-model="description"
            )
          v-col(cols="3")
            v-btn(
              dark
              small
              color="green"
              dense
              block
              :loading="ptCashVoucherDetailStorePosting"
              @click="store"
            )
              span Store
          v-col(cols="3")
            v-btn(
              dark
              small
              block
              color="error"
              dense
              @click="openDialog = false"
            )
              span Cancel
</template>
<script>
import ptCashVoucherDetailRepository from '@/repositories/pt-cash-voucher-detail.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [ptCashVoucherDetailStoreVars, ptCashVoucherDetailStoreVarNames] = requestVars({ identifier: 'pt-cash-voucher-detail-store', request: 'post', hasData: false })

const ptCashVoucherDetailStoreHandler = new VueRequestHandler(null, ptCashVoucherDetailStoreVarNames)

const inputVars = () => ({
  id: null,
  accountCodeId: null,
  description: null,
  amount: null,
})

export default {
  name: 'CreatePtCashvoucherDetail',
  props: ['value', 'accountCodes'],
  data () {
    return {
      ...ptCashVoucherDetailStoreVars,
      ...inputVars(),
    }
  },
  computed: {
    accountCode () {
      return this.accountCodes.find(item => item.account_code.id === this.accountCodeId)
    },
    balance () {
      return this.amountRemaining - this.amount
    },
    amountRemaining () {
      if (!this.accountCode) return 0
      return this.accountCode.balance
    },
    ptCashVoucherId () {
      return this.$route.params.ptCashVoucherId
    },
    openDialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    edit () {
      this.populateInputs()
    },
  },
  methods: {
    store () {
      const params = {
        title: 'Store Detail?',
        icon: 'mdi-alert-outlined',
        color: 'green',
      }
      this.$confirm('Are you sure you want to store?', params)
        .then(confirm => {
          if (!confirm) return
          const handler = ptCashVoucherDetailStoreHandler
          const repository = ptCashVoucherDetailRepository.store
          const { id, ...data } = this.getInputData()
          handler.setVue(this)
          handler.execute(repository, [data], this.reset)
        })
    },
    reset () {
      const defaults = inputVars()
      Object.keys(defaults)
        .forEach(key => {
          this[key] = defaults[key]
        })
      this.openDialog = false
    },
    getInputData () {
      return Object.keys(inputVars())
        .concat(['ptCashVoucherId'])
        .reduce((result, key) => {
          result[key.camelToSnakeCase()] = this[key]
          return result
        }, {})
    },
  },
}
</script>
